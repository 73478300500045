/*
 * T_wc_matchdayoverview
 */
$className: T_wc_matchdayoverview;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .team-name {
    text-transform: uppercase;
  }

  %match-right {
    align-items: flex-end;


    .match-block {
      .row-score {
        align-items: flex-start;
        right: auto;
        left: 30px;
      }

      .team-name {
        text-align: right;
      }

      .team-home {
        margin-left: auto;
      }

      .team-away {
        margin-left: auto;
      }

      .row-score-status {
        left: 20px;
        right: auto;
      }

      .row-score-weekday {
        left: 14px;
        right: auto;
      }
    }
  }

  %match-left {
    align-items: flex-start;

    .match-block {

      .row-score {
        align-items: flex-end;
        right: 30px;
        left: auto;
      }

      .team-name {
        text-align: left;
      }

      .team-home {
        margin-left: 0;
      }

      .team-away {
        margin-left: 0;
      }

      .row-score-status {
        right: 20px;
        left: auto;
      }
    }
  }

  .qatar-logo {
    position: absolute;
    right: 112px;
    top: 235px;
  }

  .main-header {
    top: 55px;
    padding-right: 0;
    right: 0;
    font-size: 90px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
    text-align: center;
    width: 100%;
  }

  .sub-header {
    top: 150px;
    padding-right: 0;
    right: 0;
    font-size: 50px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
    text-align: center;
    width: 100%;
  }

  .main-label-final {
    top: 320px;
    padding-right: 0;
    right: 0;
    font-size: 70px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
    text-align: center;
    width: 100%;
    color: white;
    filter: drop-shadow(2px 4px 6px black);


    div {
      margin-right: 90px;
    }
  }

  .main-label-3rd {
    top: 700px;
    padding-right: 0;
    right: 0;
    font-size: 48px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
    text-align: center;
    width: 100%;
    color: white;
    filter: drop-shadow(2px 4px 6px black);
  }

  .world-cup-trophy {
    display: block;
    position: absolute;
    left: 280px;
    bottom: -60px;
    right: 0;
    margin: 0 auto;
    width: 120px;
    z-index: 1;
    filter: drop-shadow(2px 4px 6px black);
  }

  .match-time {
    position: absolute;
    left: 0;
    bottom: -52px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    row-gap: 1px;
    width: 100%;
    flex-direction: column;

    div {
      font-size: 20px !important;
      line-height: 1;
    }
  }

  .match-block {
    position: relative;
    width: 160px;
    padding: 5px 10px;
    border: 4px solid #d2f95a;
    border-radius: 15px;
    background: radial-gradient(circle, #2e5564 0%, rgba(0, 45, 69, 1) 100%);;
    filter: drop-shadow(2px 4px 6px black);
  }

  .final-line {
    height: 2px;
    //background-color: white;
    width: 100px;
    position: absolute;
    top: 525px;
    left: 0;
    border-bottom: 5px dotted white;

    &.final-line-1 {
      left: 640px;
    }

    &.final-line-2 {
      left: 1180px;
    }
  }

  .right-lines {

    .line {

      &.line1 {
        left: auto;
        right: 220px;
        transform: rotateZ(-30deg);
      }

      &.line2 {
        left: auto;
        right: 220px;
        top: 420px;
        transform: rotateZ(30deg);
      }

      &.line3 {
        left: auto;
        right: 220px;
        top: 650px;
        transform: rotateZ(-30deg);
      }

      &.line4 {
        left: auto;
        right: 220px;
        top: 960px;
        transform: rotateZ(30deg);
      }

      &.line5 {
        left: auto;
        right: 440px;
        top: 360px;
        transform: rotateZ(-50deg);
      }

      &.line6 {
        left: auto;
        right: 440px;
        top: 720px;
        transform: rotateZ(50deg);
      }
    }
  }

  .line {
    position: absolute;
    border-bottom: 5px dotted white;
    left: 0;
    height: 2px;
    width: 100px;

    &.line1 {
      left: 220px;
      top: 110px;
      transform: rotateZ(30deg);
    }

    &.line2 {
      left: 220px;
      top: 420px;
      transform: rotateZ(-30deg);
    }

    &.line3 {
      left: 220px;
      top: 650px;
      transform: rotateZ(30deg);
    }

    &.line4 {
      left: 220px;
      top: 960px;
      transform: rotateZ(-30deg);
    }

    &.line1 {
      left: 220px;
      top: 110px;
      transform: rotateZ(30deg);
    }

    &.line5 {
      left: 440px;
      top: 360px;
      transform: rotateZ(50deg);
    }

    &.line6 {
      left: 440px;
      top: 720px;
      transform: rotateZ(-50deg);
    }

  }

  .group-table {
    position: relative;
    left: 0;
    top: 0;
    font-size: 18px;
    //padding: 0 100px;
    width: 100%;

    .row-score-status {
      color: #ff2b2b;
      font-size: 20px;
      position: absolute;
      right: 20px;
      top: 77px;
      font-weight: 400;
      text-shadow: 1px 1px 5px #000;
    }

    .row-score-weekday {
      //color: #ff2b2b;
      position: absolute;
      right: 14px;
      top: 64px;
      font-weight: 400;
      text-shadow: 1px 1px 5px #000;
      text-align: center;

      .row-time {
        flex-direction: column;
      }

      .timezone-time {
        font-size: 17px;
      }

      div {
        font-size: 20px;
      }
    }

    .row-time {
      font-size: 26px;
      display: flex;

      .timezone-time {
        padding-left: 5px;
      }
    }

    .row-group {
      position: absolute;
      left: -70px;
      font-size: 52px;
      font-weight: 300;
      color: white;
      border: 2px solid #d2f95a;
      border-radius: 100%;
      padding: 10px;
      width: 70px;
      height: 70px;
      line-height: 0.9;
      text-align: center;
    }

    .row-score {
      line-height: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      width: 115px;
      font-size: 30px;
      position: absolute;
      right: 30px;
      top: 0;
      height: 102%;

      .score-home {
        margin-bottom: 32px;
      }
    }

    .group-row {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: flex-start;
      flex-basis: 50%;
      margin-bottom: 25px;
      line-height: 1;
      flex-direction: column;
      position: relative;

      &:nth-of-type(even) {
        align-items: flex-end;

        .match-block {
          .row-score {
            align-items: flex-start;
            right: auto;
            left: 30px;
          }

          .team-name {
            text-align: right;
          }

          .team-home {
            margin-left: auto;
          }

          .team-away {
            margin-left: auto;
          }

          .row-score-status {
            left: 20px;
            right: auto;
          }
        }
      }

      //& > div {
      //  flex: 1;
      //}
    }

    .row-score {
      width: 115px;
    }

    .group-label {
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 25px;
      //text-decoration: underline;
    }

    .group-content {
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 700;
    }

    .group-rows {
      display: flex;
      width: 100%;
      //flex-direction: column;
      flex-wrap: wrap;
      position: relative;

      &.stage-8thFinals {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 0 50px;


        //ORDERING
        //ENG
        .group-row-index-3 {
          order: 7;
          @extend %match-left;
        }

        //ARG
        .group-row-index-1 {
          order: 5;
          @extend %match-left;
        }

        //PL
        .group-row-index-2 {
          order: 3;
          @extend %match-left;
        }

        //NL
        .group-row-index-0 {
          order: 1;
          @extend %match-left;
        }

        //marocco
        .group-row-index-6 {
          order: 8;
          @extend %match-right;
        }

        //Japan
        .group-row-index-4 {
          order: 6;
          @extend %match-right;
        }

        //portugal
        .group-row-index-7 {
          order: 4;
          @extend %match-right;
        }

        //brasil
        .group-row-index-5 {
          order: 2;
          @extend %match-right;
        }
      }

      &.stage-Quarter-finals {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 0 270px;

        //ORDERING
        //ENG / FRA
        .group-row-index-3 {
          order: 4;
          @extend %match-right;
        }

        .group-row-index-1 {
          order: 3;
          @extend %match-left;
        }

        .group-row-index-2 {
          order: 2;
          @extend %match-right;
        }

        .group-row-index-0 {
          order: 1;
          @extend %match-left;
        }
      }

      &.stage-Semi-finals {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 0 460px;


        //ORDERING
        //ENG / FRA
        .group-row-index-1 {
          //order: 1;
          @extend %match-right;
        }

        .group-row-index-0 {
          //order: 2;
          @extend %match-left;

        }
      }

      &.stage-Final {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        z-index: 10;

        //.team-home, .team-away {
        //  width: 82px;
        //  height: 62px;
        //}

        .row-score {
          font-size: 60px;
        }

        .row-score-status {
          right: 28px;
        }


        .group-row {
          flex-basis: auto;
          justify-content: center;
          align-items: center;
        }

        .match-block {
          width: 400px;
        }
      }

      &.stage-3rdPlaceFinal {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 630px;
        bottom: 0;
        display: flex;

        .row-score {
          font-size: 60px;
        }

        .row-score-status {
          right: 27px;
        }

        .group-row {
          flex-basis: auto;
          justify-content: center;
          align-items: center;
        }

        .match-block {
          width: 350px;
        }

        //.group-row-index-2, .group-row-index-3 {
        //  margin-bottom: 100px;
        //}
      }
    }

    .table-head {
      display: flex;
      justify-content: flex-end;
      padding: 10px 40px;
      font-size: 24px;
      font-weight: 400;
      //margin-right: 34px;
      text-align: center;
    }

    .table-row {
      display: flex;
      align-items: center;
      border-top: 3px solid #015b77;
      padding: 10px 40px;

      &:last-child {
        border-bottom: 3px solid #015b77;
      }
    }

    .table-head, .table-row {
      display: flex;
    }

    .col {
      flex: 1;
      text-align: left;
    }

    //.col-pos {
    //  //margin-right: 70px;
    //}

    .col-logo {
      margin-right: 15px;
      display: flex;
      align-items: center;


      .team-logo {
        //width: 60px;
        height: 45px;
      }
    }

    .col-team {
      margin-right: 20px;
      min-width: 600px;
      display: flex;
    }

    .col-points {
      //margin-left: 5px;
      width: 100px;
    }

    .col-diff {
      //margin-left: 5px;
      min-width: 150px;
    }

    .col-pkt {
      //margin-left: 5px;
      width: 120px;
    }

  }

  .qatar-logo {
    position: absolute;
    top: 25px;
    left: -760px;
    right: 0;
    z-index: 10;
    width: 140px;
    margin: auto;
    filter: drop-shadow(2px 4px 6px black);


  }

  .team-home, .team-away {
    width: 60px;
    height: 45px;
    background-color: #2A4354;
    border: 1px solid black;
    color: white;
    font-size: 40px;
    text-align: center;
    filter: drop-shadow(2px 4px 6px black);

    img {
      width: 100%;
      height: 100%;
    }
  }

  .team-home {
    margin-bottom: 15px;
  }

  //
  //.team-away {
  //  margin-right: 12px;
  //}
  //
  //.team-home {
  //  margin-left: 12px;
  //}

  .team-name {
    flex: 1;
    margin: 5px 0;

    //&.home {
    //  text-align: right;
    //}
    //
    //&.away {
    //  text-align: left;
    //}
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .coach-info {
    position: absolute;
    top: 650px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 60px;
    top: 350px;
    width: 340px;

  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .venue-container {
    text-transform: uppercase;
  }

  .vs-team-info {
    font-size: 26px;
  }

  .wc-content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
  }

  .lineup {
    position: absolute;
    left: 580px;
    top: 130px;
    z-index: 5;
    text-transform: uppercase;

    .lineup-content {
      font-size: 30px;
      line-height: 1.4;
    }

    .player {
      display: flex;

      .shirt-number {
        width: 50px;
        text-align: right;
        margin-right: 15px;
        font-weight: 300;
      }

      .player-name {
        font-weight: 600;
      }
    }

    .wc-headline {
      font-size: 70px;
      font-weight: 700;
    }
  }

  .lineup-reserve {
    left: 1080px;
    font-size: 40px;

    .lineup-content {
      font-size: 26px;
      //line-height: 1.4;
    }

    .player {
      .player-name {
        font-weight: 400;
      }
    }


    .wc-headline {
      margin-top: 25px;
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  .blink-me {
    animation: blink 1s linear infinite;
  }

  @keyframes blink {
    from {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    to {
      visibility: visible;
    }
  }

}

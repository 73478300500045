/*
 * T_wc_lineuptactics
 */
$className: T_wc_lineuptactics;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .green-glow {
    color: #d2f95a;
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 560px;
    height: 100%;
    background-color: #0d4d5c;
  }

  .right-block-side {
    position: absolute;
    right: 0;
    width: 1190px;
    height: 100%;
  }


  .qatar-logo {
    position: absolute;
    top: -58px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .main-header {
    top: 100px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .coach-info {
    position: absolute;
    top: 760px;
    left: 50px;
    font-size: 28px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 600;
    }

  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .team-name {
    font-size: 40px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 20px;
    white-space: nowrap;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 250px;
    width: 340px;
    height: 280px;
    right: 0;
    margin: auto;
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .venue-container {
    text-transform: uppercase;
  }

  .vs-team-info {
    font-size: 26px;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .lineup {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    text-transform: uppercase;
    width: 100%;

    .lineup-pitch {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 1190px;
      background-color: #015b78;


      .lineup-player {
        .player-name {
          height: 50px;
          overflow: hidden;
        }
      }

      img {
        height: 100%;
        width: 100%;
      }

      .formation-block {
        width: 96%;
      }
    }

    .lineup-content {
      font-size: 30px;
      line-height: 1.4;
    }

    .wc-headline {
      font-size: 70px;
      font-weight: 700;
    }
  }
}

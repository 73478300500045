&.other-stats {
  .player-bottom-gradient {
    display: none;
  }

  .line-row {
    color: #001f4b;
  }

  .player-firstname {
    left: 0;
    bottom: 33px;
    text-align: center;
  }

  .player-lastname {
    //font-weight: bold;
  }

  .player-firstname, .player-lastname {
    font-size: 29px;
    right: 0;
    letter-spacing: -0.5px;
  }

  .line2, .line4 {
    //.player-number {
    //  color: #e3001e;
    //}
    //.line-row {
    //  color: #e3001e;
    //}
    .player-firstname, .player-lastname {
      color: white;
    }
  }


  .player-group-line, .player-group-line-2 {
    height: 75px;
    align-items: center;
  }

  .player-firstname {
    bottom: 38px;
  }

  .player-lastname {
    bottom: 6px;
  }

  .names {
    position: absolute;
    left: 0;
    bottom: -75px;
    height: 74px;
  }

  .player-number {
    top: 52px;
    font-size: 100px;
  }

  .players-block-container {
    top: 120px;
  }

}


.players {
  .offset-1 {
    margin-left: 206px;
  }

  .offset-2 {
    margin-left: 412px;
  }
}

.teams-block {
  .team-logo-container {
    width: 180px;
    height: 180px;
    top: 10px;
    left: 1600px;
  }
}

&.side-away {
  .team-logo-container.home {
    display: none;
  }
}

&.side-home {
  .team-logo-container.away {
    display: none;
  }
}

/*
 * T_wc_lineuptactics
 */

$className: T_wc_lineuptactics;

.T_wc_lineuptactics.animation {

  .qatar-logo, .main-headline, .comp-pitch-tactics, .lineup-player {
    opacity: 0;
  }

}

/*
 * T_wc_goalshots
 */
$className: T_wc_goalshots;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .green-glow {
    color: #d2f95a;
  }

  .team-name {
    text-transform: uppercase;
    font-size: 28px;
    width: 100%;
    margin-right: 10px;
    height: 100%;
    line-height: 1;
    text-align: center;
    margin-top: 10px;
    font-weight: 400;
  }

  .wc-headline {
    position: absolute;
    top: 20px;
    left: 40px;
    font-size: 70px;
    font-weight: 500;
    position: absolute;
    z-index: 5;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
  }

  .versus-info {
    position: absolute;
    top: 52px;
    left: 40px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;

    .score {
      display: flex;
      font-size: 35px;
      color: #d2f95a;
      font-weight: 500;
      top: -2px;
      position: relative;
    }

    .separator {
      margin: 0 2px;
    }


    .group {
      font-weight: 700;
    }

  }

  .coach-info {
    position: absolute;
    top: 650px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 60px;
    bottom: 80px;
    width: 220px;
    left: 40px;
    height: 220px;
    height: 180px;
  }

  .qatar-logo {
    position: absolute;
    top: 56px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .venue-container {
    text-transform: uppercase;
  }

  .vs-team-info {
    font-size: 26px;
    display: flex;
    align-items: center;

    .score-separator {
      margin: 0 10px;
    }

    //& > div {
    //  display: flex;
    //}
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .legend {
    display: flex;
    font-size: 24px;
    text-transform: none;
    margin-top: 10px;

    & > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: 20px;
    }

    .shot {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      border-radius: 100%;
    }
  }

  .shot {
    background-color: #30A0B2;
  }

  .color-saved {
    background-color: #30A0B2;
  }

  .color-goal {
    background-color: #D3F66A;
  }

  .color-penalties {
    background-color: #00e235;
  }

  span.color-penalties {
    background-color: transparent;
    color: #00e235;
    font-weight: bold !important;
  }

  .color-post {
    background-color: transparent;
    border: 4px solid #30A0B2;
  }


  .color-calc-paraden {
    .stat-value {
      color: #30A0B2;
    }

  }

  .color-goals {
    .stat-value {
      color: #D3F66A;
    }
  }

  .layer-shots, .layer-positions {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .shot {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      position: absolute;
      margin-left: -9px;
      margin-top: -9px;
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
      line-height: 1;
      color: black;
    }

  }

  .dynamic-content {
    width: 100%;
    height: 560px;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .goalmouth-content {
    position: absolute;
    left: 40px;
    top: 240px;

    img {
      margin: 16px 16px -16px;
      margin-right: -1px; //width: 770px;
      //height: 290px;
    }
  }

  .layer-positions {
    .shot {
      width: 30px;
      height: 30px;
    }

  }

  .field-content {
    position: absolute;
    left: 910px;
    top: 150px;

    img {
      //width: 840px;
      //height: 480px;
    }
  }

  .stats-block {
    position: absolute;
    top: 770px;
    left: 414px;
    z-index: 10;
    font-size: 20px;
    display: flex;
    align-content: space-between;
    width: 1330px;
    justify-content: space-between;

    .stat-separator {
      width: 2px;
      height: 150px;
      background-color: #30A0B2;
    }

    .stat-label {
      font-size: 38px;
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
    }

    .stat-value {
      font-size: 75px;
      font-weight: 800;
      line-height: 1;
      margin-top: 12px;
      text-align: center;

      .sign {
        margin-left: 5px;
      }

      span {
        font-size: 45px;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }

  .score-type {
    margin-left: 5px;
  }

  .won-by-status {
    text-transform: initial;
  }

}

&.other-stats {
  .player-bottom-gradient {
    display: none;
  }

  .line-row {
    color: #001f4b;
  }

  .player-firstname {
    left: 0;
    bottom: 33px;
    text-align: center;
  }

  .player-lastname {
    //font-weight: bold;
  }

  .player-firstname, .player-lastname {
    font-size: 29px;
    right: 0;
    letter-spacing: -0.5px;
  }

  .line2, .line4 {
    //.player-number {
    //  color: #e3001e;
    //}
    //.line-row {
    //  color: #e3001e;
    //}
    .player-firstname, .player-lastname {
      color: white;
    }
  }

}

//.line4 {
//  .player-group-line {
//    width: inherit;
//  }
//}


.player-group-line, .player-group-line-2 {
  & > div {
    display: none;
  }
}

.player-group-line {
  width: 620px;
}

.player-group-line-2 {
  width: 460px;
  right: 0;

}


.names {

  .player-data {
    width: 205px;

    > div {
      font-size: 28px;
      bottom: 12px;
    }
  }
}

.player-group-lines {
  position: absolute;
  width: 100%;

  .player-group-line-2 {
    display: flex;
  }
}

.player-number {
  top: 46px;
  right: 0;
  bottom: 182px;
  font-size: 100px;
}


.players {
  .offset-1 {
    margin-left: 206px;
  }

  .offset-2 {
    margin-left: 412px;
  }
}


.player-image {
  width: 205px;
  height: 330px;
  top: 52px;
}

.players-block-container {
  padding: 0px 40px;
  top: 340px;
}

.pos-forward.last-of-pos {
  margin-right: 70px;
}


.lineGoalie {

  .player-group-line {
    width: 440px;
  }
}

.headline {
  font-size: 210px;
}

.team-vs {
  position: absolute;
  left: 690px;
  top: 1145px;
  font-size: 220px;
  color: #054888;
  line-height: 1;
  z-index: 3;
  transform-origin: center center;
}

.teams-block {
  position: absolute;
  top: -20px;
  width: 100%;
  left: 60px;


  .home, .away {
    position: absolute;
    width: 220px;
    height: 220px;
    z-index: 44;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .team-logo-container {
    top: 1150px;
    left: 790px;
  }


  .home {
    left: 590px;

  }

  .away {
    left: 853px;
    top: 1140px;
  }
}

.sponsor-del {
  left: 0;
  width: 150px;
  top: 1370px;
  right: 0;
  margin: auto;
}

&.side-away {
  .team-vs {
    color: #e40520;
  }
}

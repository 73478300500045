.comp-match-score-widget {
  position: absolute;
  left: 0;
  top: 0;
  /* height: 500px; */
  /* width: 200px; */
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .animation-container {
    overflow: hidden;
  }

  .widget-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-container {
      position: relative;
    }

    .team-vs {
      position: relative;
      text-align: center;
      left: 0px;
      top: 0;
      font-size: 48px;
      font-weight: 700;
      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin: 0 20px;


      .versus {
        background-color: #bfcfd4;
        border: 4px solid #72949e;
        border-radius: 100%;
        //display: inline-block;
        //padding: 10px;
        color: #005175;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        letter-spacing: -2px;
        font-size: 28px;
        font-weight: 600;
      }
    }

    .home {
      //margin-right: 200px;
    }

    .home, .away {
      width: 140px;
      height: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        //width: 100%;
        //height: 100%;
        //object-fit: contain;
      }
    }

    .team-name {
      font-size: 20px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 10px;
      display: none;
    }
  }

  .score {
    font-size: 70px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    line-height: 1;

    .score-type {
      color: #d2f95a;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      position: absolute;
      bottom: 7px;
    }

    .score-home {
      width: 50px;
      text-align: right;
    }

    .score-away {
      width: 50px;
      text-align: left;
    }

    .separator {
      margin: 0 5px;
      position: relative;
      bottom: 2px;
    }
  }
}

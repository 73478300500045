/*
 * T_adlerteamstats
 */

$className: T_adlerteamstats;

.#{$className} {
  font-family: "Predator-Sans-Rounded";
  -webkit-font-smoothing: antialiased;
  color: #0F1F3A;


  .headline, .last-x-games {
    transform-origin: left center;
  }

  .team-logo-container {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 385px;
    right: 110px;
    z-index: 44;

    img {
      //transform: rotateZ(
      //    22deg);
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: relative;
    }
  }

  .headline {
    font-size: 130px;
    top: 80px;
    left: 70px;
    z-index: 15;
    color: #fff;
    position: relative;
    text-align: left;

    .stroke-shadow, .stroke-shadow-2 {
      top: -11%;
      right: 0;
      left: 0;
      bottom: 0;
      font-size: 125px;
      position: absolute;
      color: white;
      -webkit-text-fill-color: transparent;
    }
  }

  .sponsors {
    position: absolute;

    &.sponsor-sap {
      right: 70px;
      //width: 260px;
      bottom: 70px;
    }
  }

  .team-stats {
    z-index: 7;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  .team-stats-block {
    display: flex;
    width: 1375px;
    /* transform-origin: center; */
    position: absolute;
    top: 410px;
    left: 70px;
    justify-content: flex-start;
    line-height: 1;
    height: 238px;
    overflow: hidden;
    z-index: 8;

    .team-stats:first-child {
      //margin-right: 180px;
    }

    .team-stats:last-child {
      //margin-right: 150px;
    }

    .value {
      font-size: 110px;
      text-align: center;
      color: white;
    }

    .label {
      text-align: center;
      letter-spacing: 0;
      font-size: 42px;
      width: 260px;
      color: #fff;
      position: relative;
      top: -2px;
    }
  }


  .box-bg {
    display: flex;
    position: absolute;
    z-index: 10;
    left: -180px;
    top: -68px;

    /* 2*sin(120/2)*h = w
       h = w / (sin(60)*2)
       h = w * 0.57736
    */
    .triangle1:before {
      content: "";
      display: block;
      padding-top: 66%;
    }

    .triangle1 {
      background: #014389;
      clip-path: polygon(0 50%, 50% 100%, 100% 50%, 50% 0);
      width: 190px;
      display: inline-block;
      margin: 5px;
    }
  }

  &.side-away {
    .triangle1 {
      background: #e60026;
    }
  }

  &.side-home {
    .team-stats-block {
      //.label {
      //  background-color: #e40019;
      //}
    }
  }

  &.side-away {
    .team-stats-block {
      //.label {
      //  background-color: #003f8a;
      //}
    }
  }

  .line-stats-container {
    color: white;

    .stats-mark-points {
      display: flex;
      position: absolute;
      z-index: 10;
      bottom: -20px;

      & > div {
        //border-left: 2px solid #7b0003;
        height: 10px;
        width: 45.5px;

        &:last-child {
          //border-right: 2px solid #7b0003;
        }
      }
    }

    .stat-line-home-container {
      //background-color: #00143a;

      .label, .value {
        position: absolute;
        width: 100%;

      }

      .label {
        top: -36px;
        text-align: left;
        font-size: 46px;
        right: 500px;
      }

      .value {
        text-align: left;
        font-size: 46px;
        bottom: -32px;
        z-index: 20;
        /* left: auto; */
        width: 140px;
        right: auto;
        left: -150px;
        text-align: center;

        span {
          font-size: 95px;
          position: absolute;
          top: -42px;
          opacity: 0.5;
          /* right: 0; */
          /* right: 0; */
          left: 110px;
          display: none;
        }
      }

      .stats-mark-points {
        & > div {
          border-left: 2px solid #ffffff;

          &:last-child {
            border-right: 2px solid #ffffff;
          }
        }
      }


      &.unterzahl {
        left: 570px;
        top: 720px;
      }

      &.uberzahl {
        left: 570px;
        top: 900px;
      }
    }

    .stat-line-home-container, .stat-line-away-container {
      position: absolute;
      //left: 151px;
      top: 25px;
      height: 4px;
      width: 650px;
      //overflow: hidden;
      transform-style: preserve-3d;
      //border: 3px solid black;
      box-sizing: border-box;
      //border-radius: 20px;
      //background-color: #000000;

      .filler:after {
        content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: auto;
        right: 0;
        z-index: 15;
        transform-style: preserve-3d;
      }

    }

    .filler {
      width: 0;
      height: 100%;
      position: absolute;
      //top: -3%;
      left: 0;
      z-index: 8;
      overflow: hidden;
      transform-style: preserve-3d;
      //background-color: #e40019;
      //border-radius: 20px;
      display: flex;
      flex-direction: column;
      height: 17px;
      margin-top: -8.5px;
      justify-content: space-between;


      > div {
        height: 6px;
        width: 100%;
        background-color: white;
      }
    }

    .stat-line-home-container {
      right: 355px;
    }

    .stat-line-away-container {
      right: auto;
      left: 355px;

    }
  }

  &.side-away {
    .stat-line-home-container {
      right: 355px;
    }

    .last-x-games {
      .label {
        color: #fff;
      }
    }
  }

  .line-stats-container {
    .stat-line-home-container {
      //background-color: white;
      //border: 3px solid white;
    }
  }


  .team-block-line {
    position: absolute;
    height: 60px;
    background-color: #054888;
    font-size: 63px;
    line-height: 56px;
    z-index: 6;
    top: 98px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div:nth-child(2) {
      position: absolute;
      left: 1016px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &.side-away {
    .team-block-line {
      background-color: #e60026;
    }
  }

  .last-x-games {
    position: absolute;
    top: 265px;
    /* left: 369px; */
    color: white;
    font-size: 36px;
    line-height: 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    left: 70px;

    .last-games-container {
      display: flex;
      justify-content: center;
      margin-left: 10px;
      left: 0;
      position: relative;
      //font-size: 47px;

      & > div {
        position: relative;
        //display: flex;
        white-space: nowrap;
        margin: 0 2px;

        //margin-right: 40px;

        &:last-child {
          margin-right: 0;
        }

        .separator {
          top: 0;
          right: -50px;
          position: absolute;
          //margin-left: 20px;
        }
      }
    }
  }

  .line-stats-container {
    .stats-mark-points {
      display: none;
    }
  }


  //.filler-point-base {
  //  position: absolute;
  //  z-index: 40;
  //  left: -59px;
  //  top: -30px;
  //  height: 60px;
  //  width: 60px;
  //  background-color: #024289;
  //  box-shadow: 0 0 8px 2px #66abf2;
  //  border-radius: 100%;
  //}

  .filler-container {
    //overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }


}

/*
 * T_wc_seasongroupoverview
 */

$className: T_wc_seasongroupoverview;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .green-glow {
    color: #d2f95a;
  }

  .main-title {
    font-size: 70px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    position: absolute;
    left: 15px;
    text-align: center;
    right: 0;
    top: 35px;
    z-index: 10;

    span {
      color: white;
    }
  }

  .wc-content {
    position: absolute;
    width: 1920px;
    height: 1080px;
    left: 0;
    top: 0;
    z-index: 10;
  }

  .qatar-logo-container {
    position: absolute;
    top: 475px;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
  }

  .qatar-logo {

    width: 140px;
    filter: drop-shadow(2px 4px 6px black);

    img {
      width: 100%;
    }
  }

  .headline {
    font-size: 90px;
    top: 50px;
    z-index: 15;
    color: #fff;
    position: relative;
    text-align: center;

    .stroke-shadow, .stroke-shadow-2 {
      top: -9%;
      right: 0;
      left: 0;
      bottom: 0;
      font-size: 110px;
      position: absolute;
      color: white;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
    }
  }

  .sponsors {
    position: absolute;

    &.sponsor-sap {
      right: 65px;
      width: 260px;
      top: 90px;
    }
  }

  .live-matchdays-group {
    //width: 800px;
    //height: 960px;
    position: relative;
    /* margin-top: 150px; */
    /* display: flex; */
    height: 100%;
    display: flex;
    align-items: center;
    /* display: flex; */
    //left: 555px;
    //top: 80px;

    //&.group-d {
    //  margin-bottom: 70px;
    //}

    .group-label {
      color: #d2f95a;
      font-size: 26px;
      text-align: center;
    }
  }

  .live-matchdays-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    margin: auto;
    overflow: hidden;
    color: #fff;

    .page-1, .page-2 {
      display: flex;
      flex-wrap: wrap;
      //align-self: center;
      align-content: center;
      //align-items: center;
      align-content: center;
      justify-content: space-evenly;
      padding-top: 100px;
      position: absolute;
    }

    .page-2 {
      opacity: 0;
    }

    .match {
      line-height: 1;
      display: flex;
      justify-content: center;
      //height: 145px;
      align-items: center;
      font-size: 26px;
      padding: 15px 0;


      border-bottom: 4px solid transparent;
      border-image-slice: 1;
      border-image-source: radial-gradient(circle, #d2f95a 40%, transparent 100%);
      border-radius: 25px;

      &:first-child, &:nth-child(2) {
        border-top: 4px solid transparent;
        border-image-slice: 1;
        border-image-source: radial-gradient(circle, #d2f95a 40%, transparent 100%);
      }

      //&.adler-match {
      //  color: #ff2222;
      //}

      &.status-live {
        .match-status {
          color: #ff2222;
          text-transform: uppercase;
        }
      }

      .score-container {
        display: flex;
        justify-content: center;
        position: relative;
        width: 200px;
        font-size: 36px;
        line-height: 1;
        //margin-top: 25px;
      }
    }


    .home-logo, .away-logo {
      height: 100%;


      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .home-logo {
      margin-left: 45px;
    }

    .away-logo {
      margin-right: 45px;
    }

    .team-logo-container {
      &.home, &.away {
        width: 110px;
        height: 100px;

        img {
          //width: 100%;
          //height: 100%;
          //object-fit: contain;
        }
      }

      &.home {
        margin-left: 20px;
      }

      &.away {
        margin-right: 20px;
      }
    }


    .home-name {
      width: 380px;
      text-align: right;
      white-space: nowrap;
    }

    .away-name {
      width: 380px;
      text-align: left;
      white-space: nowrap;
    }

    .match-status {
      position: absolute;
      bottom: -18px;
      width: 100%;
      text-align: center;
      font-size: 16px;
    }

    .group-info {
      position: absolute;
      top: -48px;
      width: 100%;
      text-align: center;
      font-size: 19px;
    }

    .attendance-info {
      position: absolute;
      top: -26px;
      width: 100%;
      text-align: center;
      font-size: 19px;
      color: #d2f95a;
    }

    .date-info {
      position: absolute;
      top: 58px;
      width: 450px;
      text-align: center;
      font-size: 18px;
      color: #ffffff;
      font-weight: 300;
    }
  }

  .extra-time-info {
    font-size: 26px;
    color: white;
    position: absolute;
    left: 146px;
    top: 20px;
    color: #ff0000;
  }

  .group-matches {
    display: flex;
    flex-wrap: wrap;
    height: 750px;

    .match {
      width: 50%;
    }
  }

  .status-string-time {
    color: #d2f95a;
  }

  .blink-me {
    animation: blink 1s linear infinite;
  }

  @keyframes blink {
    from {
      visibility: hidden;
    }
    50% {
      visibility: hidden;
    }
    to {
      visibility: visible;
    }
  }


}

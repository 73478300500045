@import "src/layouts/fonts/edosz.scss";
@import "src/layouts/fonts/predator.scss";

.app-adler {
  .timeline-container {
    width: inherit;
    height: inherit;
  }

  .template-container {
    position: absolute;
    left: 0;
    top: 0;
    //background-color: red;
  }

  .outro-optional-video {
    position: absolute;
    z-index: 5000;
    opacity: 0;
    pointer-events: none;
  }

  .playoffs-headline {
    font-family: "Predator-Sans-Cut";
    background: white;
    //background: linear-gradient(to top, #E20231 0%, #9C1313 45%, #E20231 51%, #9C1313 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    text-transform: uppercase;
  }

  video.template-background {
    width: 100%;
  }

  ///* add a single stroke */
  //.playoffs-headline:before {
  //  content: attr(data-score);
  //  position: absolute;
  //  -webkit-text-stroke: 12px #fff;
  //  left: 0;
  //  z-index: -1;
  //  width: 100%;
  //}
  //
  ///* add a single stroke */
  //.playoffs-headline:after {
  //  content: attr(data-score);
  //  position: absolute;
  //  -webkit-text-stroke: 24px #0F1F3A;
  //  left: 0;
  //  z-index: -2;
  //  width: 100%;
  //}

  //.side-away {
  //  .comp-template-block {
  //    background-color: #8B0E0D;
  //  }
  //}

  .f-1920x1080 {
    .sponsor-del {
      display: none;
    }
  }

}

/*
 * T_wc_mvp
 */
$className: T_wc_mvp;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;

  .green-glow {
    color: #d2f95a;
  }

  .left-player {
    top: 170px;
    width: 670px;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .qatar-logo {
    position: absolute;
    top: 65px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .main-header {
    top: 100px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;

  }

  .info-block {
    position: absolute;
    top: 580px;
    left: 100px;
    font-size: 30px;
    font-weight: 400;
  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .teams-block {
    display: flex;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
    /* width: 100%; */
    justify-content: space-between;
    align-items: center;

    .home, .away {
      //position: absolute;
      height: 100px;
      //height: 200px;
      margin-top: 15px;

      img {

        &.svg-logo {
          padding: 0;
        }

        //width: 100%;
        //height: 100%;
        //object-fit: contain;
      }
    }

    .team-name {
      font-size: 30px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: 400;
      margin-top: 10px;
      white-space: nowrap;
    }
  }

  .main-headline {
    font-size: 300px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 120px;
    top: 100px;
    justify-content: center;
    align-items: center;

    span {
      display: block;
      line-height: 1;
      height: 240px;
    }
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }
  }


  .mvp-block {
    position: absolute;
    width: 1080px;
    height: 100%;
    top: 0;
    right: 0;
  }


  .teams-score {
    display: flex;
    position: absolute;
    /* right: 221px; */
    top: 70px;
    left: 420px;

    .score {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 50px;
      font-weight: 700;
      line-height: 1.1;

      .separator {
        margin: 0 5px;
        margin-bottom: 5px;
      }
    }


    .team-logo-container {
      //position: absolute;
      z-index: 5;
      //left: 60px;
      //top: 350px;
      //width: 80px;
      height: 80px;
      display: flex;

      img.svg-logo {
        padding: 0;
      }

      &.home {
        margin-right: 30px;
      }

      &.away {
        margin-left: 30px;
      }
    }
  }

  .team-stats {
    position: absolute;
    left: 464px;
    top: 170px;
    font-size: 32px;
    width: 560px;

    .stat-label {
      text-transform: uppercase;
      font-weight: 500;
      color: #d2f95a;
    }

    .stat-row {
      display: flex;
      align-items: center;
      border-top: 3px solid #015b77;
      padding: 10px 0;
      justify-content: space-between;

      .stat-away, .stat-home {
        font-size: 35px;
      }

      &:last-child {
        border-bottom: 3px solid #015b77;
      }
    }
  }


  .venue-container {
    text-transform: uppercase;
  }
}

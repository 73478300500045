/*
 * T_wc_lineup
 */
$className: T_wc_lineup;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .green-glow {
    color: #d2f95a;
  }

  //
  //.teams-block {
  //  display: flex;
  //  position: absolute;
  //  left: 0;
  //  right: 0;
  //  /* width: 100%; */
  //
  //  &.home {
  //    justify-content: center;
  //  }
  //
  //  &.away {
  //    justify-content: center;
  //  }
  //
  //  .home,
  //  .away {
  //    //position: absolute;
  //    width: 400px;
  //    height: 350px;
  //    //height: 200px;
  //
  //    img {
  //      //width: 100%;
  //      //height: 100%;
  //      //object-fit: contain;
  //    }
  //  }
  //
  //  .team-name {
  //    font-size: 40px;
  //    line-height: 1;
  //    text-align: center;
  //    text-transform: uppercase;
  //    font-weight: 300;
  //    margin-top: 10px;
  //    white-space: nowrap;
  //  }
  //}

  .team-name {
    font-size: 40px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 20px;
    white-space: nowrap;
  }

  .qatar-logo {
    position: absolute;
    top: 42px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .main-header {
    top: 100px;
    left: 100px;
    font-size: 160px;
    position: absolute;
    line-height: 0.9;
    text-transform: uppercase;
    font-weight: 700;
    z-index: 5;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .coach-info {
    position: absolute;
    top: 760px;
    left: 50px;
    font-size: 28px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 600;
    }

  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 250px;
    width: 340px;
    height: 280px;
    right: 0;
    margin: auto;
  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .left-block-side {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 560px;
    height: 100%;
    background-color: #0d4d5c;
  }

  .right-block-side {
    position: absolute;
    right: 0;
    width: 1190px;
    height: 100%;
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .venue-container {
    text-transform: uppercase;
  }

  .vs-team-info {
    font-size: 26px;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
  }

  .lineup {
    position: absolute;
    left: 80px;
    top: 140px;
    z-index: 5;
    text-transform: uppercase;

    .lineup-content {
      font-size: 30px;
      line-height: 1.4;
      width: 460px;
    }

    .player {
      display: flex;
      border-bottom: 2px solid #367f8b;
      padding: 9px 0;

      .shirt-number {
        width: 50px;
        text-align: left;
        margin-right: 15px;
        font-weight: 300;
      }

      .player-name {
        font-weight: 600;
      }
    }
  }

  .lineup-reserve {
    left: 630px;
    font-size: 38px;

    .lineup-content {
      font-size: 26px;
      //line-height: 1.4;
    }

    .player {
      .player-name {
        font-weight: 400;
      }
    }
  }

  .wc-headline {
    font-size: 60px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 12px;
    text-transform: uppercase;
    margin-left: 50px;
  }
}

.timeline-container {

  .template-container {
    overflow: hidden;

    &.active-slide {
      .comp-template-block {
        opacity: 0;
      }
    }

    &.next-slide {
      .comp-template-block {
        opacity: 0;
      }
    }
  }


}

/*
 * T_wc_tournamentranking
 */
$className: T_wc_tournamentranking;

.#{$className} {
  font-family: "Rubik";
  //font-weight: 400;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5715;


  .qatar-logo {
    position: absolute;
    top: 55px;
    right: 56px;
    z-index: 10;
    margin: auto;
    width: 320px;
    filter: drop-shadow(2px 2px 2px black);

    img {
      width: 100%;
    }
  }

  .competition-logo-container {
    border-radius: 20px;
    height: 100px;
    width: 150px;
    box-sizing: border-box;
    align-self: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .competition-positioner {
    position: absolute;
    left: 0;
    top: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 380px;
    width: 100%;
    margin-left: 40px;
  }

  .second-title {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
    padding-left: 26px;
    display: flex;
    align-items: center;
    width: inherit;
    line-height: 1;
  }


  .group-table {
    position: relative;
    left: 0;
    top: 0;
    font-size: 28px;

    .group-label {
      font-size: 80px;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      margin-bottom: 10px;
    }

    .group-content {
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 700;
      width: 1580px;
      margin-top: 110px;
    }

    .table-head {
      display: flex;
      justify-content: flex-end;
      padding: 10px 40px;
      font-size: 20px;
      font-weight: 400;
      //margin-right: 34px;
      text-align: center;

      .col-points {
        line-height: 1.2;
      }
    }

    .table-row {
      display: flex;
      align-items: center;
      border-top: 4px solid #367f8b;
      padding: 10px 40px;

      &:last-child {
        border-bottom: 4px solid #367f8b;
      }
    }

    .col-pos {
      margin-right: 20px;
    }

    .col-logo {
      margin-right: 20px;
      display: flex;
      align-items: center;
      width: 80px;

      img.svg-logo {
        padding: 0;
      }

      .team-logo {
        //width: 60px;
        height: 65px;
      }
    }

    .col-team {
      margin-right: 20px;
      //min-width: 800px;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
    }

    .col-points {
      min-width: 190px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .main-header {
    font-size: 60px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    line-height: 1;
    margin-bottom: 50px;
    z-index: 100;
    position: relative;
    top: 43px;
    text-transform: uppercase;
  }

  .versus-info {
    position: absolute;
    top: 60px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .coach-info {
    position: absolute;
    top: 650px;
    left: 60px;
    font-size: 30px;
    font-weight: 400;
    z-index: 5;
    text-transform: uppercase;
    line-height: 1.2;
    padding-left: 16px;
    border-left: 3px solid #006e89;

    .group {
      font-weight: 700;
    }

  }

  .competition-info {
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .team-logo-container {
    position: absolute;
    z-index: 5;
    left: 60px;
    top: 350px;
    width: 340px;

  }

  .sponsors {
    position: absolute;
    top: 15px;

    &.sponsor-del {
      left: 49px;
      width: 150px;
      top: 70px;
    }

    &.sponsor-sap {
      right: 50px;
      width: 260px;
      top: 70px;
    }
  }

  .headline {
    font-family: "EdoSZ";
    font-size: 215px;
    position: absolute;
    top: 463px;
    text-align: center;
    left: 0;
    width: 100%;
  }

  .date-container {
    display: flex;
  }

  .gameInfo {
    //font-size: 42px;
    font-weight: 600;
    position: relative;
    margin-top: 50px;
    //position: absolute;
    //top: 881px;
    //right: 328px;
    text-align: center;
    line-height: 1;
    letter-spacing: -2px;
    text-transform: uppercase;


    .hashtag {
      //margin-right: 46px;
      color: #e40520;
    }

    .date-container {
      margin-top: 6px;
      //font-size: 50px;
    }

    .separator {
      margin: 0 5px;
    }


    span {
      font-family: "Predator-Sans-SemiLight";
    }


  }

  .venue-container {
    text-transform: uppercase;
  }

  .vs-team-info {
    font-size: 26px;
  }

  .wc-content {
    position: absolute;
    width: 1730px;
    height: 860px;
    left: 95px;
    top: 130px;
    z-index: 10;
    display: flex;
    justify-content: center;
  }

  .lineup {
    position: absolute;
    left: 580px;
    top: 130px;
    z-index: 5;
    text-transform: uppercase;

    .lineup-content {
      font-size: 30px;
      line-height: 1.4;
    }

    .player {
      display: flex;

      .shirt-number {
        width: 50px;
        text-align: right;
        margin-right: 15px;
        font-weight: 300;
      }

      .player-name {
        font-weight: 600;
      }
    }

    .wc-headline {
      font-size: 70px;
      font-weight: 700;
    }
  }

  .lineup-reserve {
    left: 1080px;
    font-size: 40px;

    .lineup-content {
      font-size: 26px;
      //line-height: 1.4;
    }

    .player {
      .player-name {
        font-weight: 400;
      }
    }


    .wc-headline {
      margin-top: 25px;
      font-size: 50px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  .target-player {
    .col-points {
      min-width: 250px;
    }
  }
}

/*
 * T_wc_matchdayoverview
 */

$className: T_wc_matchdayoverview;

.T_wc_matchdayoverview.animation {
  .group-rows, .main-header, .qatar-logo, .left-lines, .right-lines, .main-label-final > img, .main-label-final > div, .main-label-3rd {
    opacity: 0;
  }


  //.main-label-final > img {
  //  //animation: breathing 2s ease-out infinite normal;
  //  //animation: blink-animation 1.5s steps(10, start) infinite;
  //}
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  100% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
